import { ref } from "vue";

export const addPage = (itemCount) =>  {
    let currentPage = ref(0);
    let maxPage = ref(0);
    let itemPerPage = itemCount;

    const changePage = (index) => {
        currentPage.value += index;
        window.scrollTo({
            top: document.querySelector('.page-banner').clientHeight,
            behavior: 'smooth'
        });
    };

    return { changePage, currentPage, maxPage, itemPerPage };
};