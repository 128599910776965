<template>
    <div>
        <news-row
            v-for="(item, index) in newsItems"
            :key="`news-${index}`"
            :id="item.id"
            :date="item.date"
            :title="item.title"
            date-color="second"
            title-color="main"
            border-color="second"
        ></news-row>
        <div v-if="newsItems.length > page.itemPerpage" class="p-d-flex p-jc-center p-mt-2 p-mt-md-4 p-mt-lg-6">
            <custom-button v-if="page.currentPage.value > 0" class="p-ml-4" width="160px" text="PREV" bg-color="white" border-color="#4878b0" @click="page.changePage(currentPage, -1)"></custom-button>
            <custom-button v-if="page.currentPage.value < page.maxPage.value" class="p-ml-4" width="160px" text="NEXT" bg-color="white" border-color="#4878b0" @click="page.changePage(currentPage, 1)"></custom-button>
        </div>
    </div>
</template>

<script>
    import { onMounted, ref } from "vue";
    import NewsRow from "@/components/news/NewsRow.vue";
    import CustomButton from "@/components/common/CustomButton";
    import { addPage } from "@/utils/page";
    import { apiExecutor } from "@/api";

    export default {
        name: 'NewsList',
        components: { NewsRow, CustomButton },
        setup() {
            const page = addPage(5);
            let newsItems = ref([]);

            onMounted(async () => {
                newsItems.value = await apiExecutor.getNewsList();
                page.maxPage.value = Math.round((newsItems.value.length) / page.itemPerPage);
            });

            return { newsItems, page };
        }
    }
</script>