<template>
    <div class="news-row p-grid p-pb-3 p-pt-4" :class="`border-bottom-${borderColor}`">
        <div class="p-col-12 p-sm-3 p-md-2 p-d-flex p-align-center">
            <span :class="`text-${dateColor}`">{{ rowDate }}</span>
        </div>
        <div class="p-col-12 p-sm-9 p-md-10 p-d-flex p-align-center">
            <router-link :to="`/news/${id}`" :class="`text-${titleColor}`">{{ title }}</router-link>
        </div>
    </div>
</template>

<script>
    import { ref } from "vue";
    import { formatDate } from "../../utils/date";

    export default {
        name: 'NewsList',
        props: {
            id: Number,
            date: String,
            title: String,
            dateColor: String,
            titleColor: String,
            borderColor: String
        },
        setup(props) {
            const rowDate = ref(formatDate(props.date));
            return { rowDate };
        }
    }
</script>